$base-color: #1759a5;
$black-color: #1e2e3f;
$border-inputs: #295aa0;
$color-focus: #42a5f5;
$note-color: #81878d;
$border-color: #e2e2e2;
$white-color: #ffffff;
$black: #000000;

// @media screen

$small: 600px;
$medium: 900px;
$large: 1200px;

@font-face {
  font-family: "poppinsregular";
  src: url("./assets/fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsbold";
  src: url("./assets/fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("./assets/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirnextbold";
  src: url("./assets/fonts/AvenirNextBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-radio-button {
  margin-right: 40px !important;
  @media screen and (max-width: $small) {
    width: 100%;
  }
  span {
    font-weight: bold;
    font-size: 14px;
  }
}

.input-text {
  width: 100%;
  input {
    height: 5px;
    border: 1px solid $border-inputs;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  }
}

.input-text_signup {
  width: 100%;
  input {
    height: 5px;
    background-color: $white-color;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  }
  input::placeholder {
    color: $black;
  }
}

.input-number_format {
  width: 60%;
  @media screen and (max-width: $small) {
    width: 100%;
  }
  input {
    height: 20px;
    border: 1px solid;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  }
}

.input-email {
  width: 100%;
  input {
    height: 5px;
    background-color: $white-color;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  }
  input::placeholder {
    color: $black;
  }
}

.input-password {
  width: 100%;
  input {
    height: 5px;
    background-color: $white-color;
    border-radius: 5px;
  }
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  input::placeholder {
    color: $black;
  }
}

.input-select {
  width: 100%;
  div {
    border: 1px solid $border-inputs;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
    padding: 9px;
  }
}

.input-select_signup {
  width: 100%;
  div {
    background-color: $white-color;
    border: 1px solid $border-inputs;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
    padding: 9px;
  }
}

.input-textarea {
  box-sizing: border-box;
  border: 1px solid $border-inputs;
  border-radius: 5px;
  box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 20%);
  padding: 16.5px 14px;
  font-family: inherit;
  color: $color-focus;
  font-size: 1rem;

  &:focus {
    outline: 1px solid $border-inputs;
  }

  &:hover {
    border: 1px solid $color-focus;
  }
}

.input-checkbox {
  span {
    &:nth-child(2) {
      color: $black-color;
      font-weight: bold;
    }
  }
}

.nested-input_checkbox {
  width: 15px;
  height: 15px;
}

.input-date-picker {
  width: 100%;
  height: 40px;
}

.react-date-picker__wrapper {
  border-radius: 5px;
}

.numberInputs {
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
}

.numberInputs {
  &.separate {
    min-width: 88px;
  }
}

.divider {
  margin-bottom: 28px;
}

.subtitle2 {
  color: $base-color;
  span {
    font-weight: 100;
  }
}

.note {
  color: $note-color;
}

.table {
  th,
  td {
    border: 1px solid rgba(224, 224, 224, 1) !important;
    color: $black-color;
    font-weight: bold;
  }
}

.table-container {
  border-radius: 10px !important;
}

.contain-shareholder {
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 30px;
}

.header-pdf {
  color: #1759a5;
  text-align: center;
}

.body-pdf,
.header-pdf {
  padding: 0 20px;
}

.body-pdf .body-pdf-section {
  margin: 1rem auto;
}

.body-pdf p {
  margin: 2px 0;
  font-size: 10px;
  color: #4b4848;
}

.body-pdf p b {
  color: #4b4848;
}

.body-pdf h6 {
  margin: 10px 0;
  font-size: 11px;
  border-bottom: 1px solid;
  display: inline-block;
}

.body-pdf hr,
.header-pdf hr {
  border-color: #1759a5;
}

.types-powers {
  display: flex;
  @media screen and (max-width: $small) {
    display: grid;
  }
}

.indicative {
  font-weight: bold;
}

.types-powers_title {
  font-size: 14px;
  margin-right: 30px !important;
}

.commissioner-icon {
  display: flex;
  color: $base-color;
  align-items: center;
}

.separate {
  margin-top: 30px;
  margin-bottom: 30px;
}

.tss-58la38-MUIDataTable-paper {
  display: "contents";
}
