@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.mg-bottom {
    margin-bottom: 200px;
}
.view__donation__container {
    max-width: 1200px;
    margin: 0 auto;
}
.donation__container {
    max-width: 1080px;
    margin: 0 auto;
}
.donation__container h2 {
    text-align: center;
    font-size: 60px;
    font-family: 'Poppins', sans-serif;
    color: #1759A5;
}
.donation__container-description {
    color: #1e2e3f;
    font-weight: 600;
    font-size: 16px;
    margin-left: 30px;
}
.principal__component {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80vh;
    margin: 40px auto;
    font-family: 'Poppins', sans-serif;
}
.principal__component .submit-button {
    width: 140px;
    padding: 10px 10px 10px 20px;
    margin: 20px auto;
    border: none;
    border-radius: 20px;
    background-color: #1759A5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}
.principal__component .submit-button:hover {
    background-color: #204a85;
}