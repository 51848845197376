@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.inputs__container {
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    padding: 20px 30px;
}
.inputs__container-note {
    color: #1759A5;
    font-size: 14px;
    margin-bottom:30px;
}
.inputs__elements__style-amount p {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: #1759A5;
}
.inputs__elements__style-amount input {
    box-sizing: border-box;
    max-width: 200px;
    height: 40px;
    outline: none;
    border: 1px solid #295AA0;
    border-radius: 5px;
    padding: 0 10px;
    transition: all 0.3s ease;
    font-size: 14px;
    font-family: 'Poppins' sans-serif;
    color: #1759A5;
}
.inputs__container h3 {
    color: #295AA0;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 20px;
}
.appearing__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    height: 100px;
    margin-bottom: 0px;
}
.appearing__container-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #295AA0;
    border-radius: 10px;
    min-width: 200px;
    height: 40px;
}
.appearing__container-element p {
    margin: 0;
    margin-right: 10px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #1759A5;
    cursor: pointer;
    transition: all .3s ease;
}
.appearing__container-element p:hover {
    transform: scale(1.02);
}
.appearing__container-element i {
    margin-right: 10px;
    color: #1759A5;
    cursor: pointer;
    transition: all .3s ease;
}
.appearing__container-element i:hover {
    transform: scale(1.3);
    rotate: 90deg;
}
.appearing__container-element.selected {
    background-color: #c2ebf8;
}
.add-appearing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #295AA0;
    color: #fff;
    border: 0;
    padding: 12px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);
    transition: all .3s ease;
    width: 200px;
    height: 40px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease;
}
.add-appearing:hover {
    transform: scale(1.02);
}
.pd-bottom {
    padding-bottom: 30px;
}
.first__section {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.inputs__elements__style {
    box-sizing: border-box;
}
.inputs__elements__style p {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: #1759A5;
}
.inputs__elements__style input {
    box-sizing: border-box;
    min-width: 320px;
    max-width: 328px;
    height: 40px;
    outline: none;
    border: 1px solid #295AA0;
    border-radius: 5px;
    padding: 0 10px;
    transition: all 0.3s ease;
    font-size: 14px;
    font-family: 'Poppins' sans-serif;
    color: #1759A5;
}
.inputs__elements__style input:hover {
    border: 1px solid #72D8F7;
}
.inputs__elements__style input:focus {
    outline: 1px solid #295AA0;
    border: 1px solid #295AA0;
}
.inputs__elements__style select {
    min-width: 320px;
    max-width: 320px;
    height: 40px;
    outline: none;
    border: 1px solid #295AA0;
    border-radius: 5px;
    padding: 0 10px;
    transition: all 0.3s ease;
    font-size: 14px;
    font-family: 'Poppins' sans-serif;
    color: #1759A5;
    cursor: pointer;
}
.inputs__elements__style-check {
    width: 100%;
    margin-bottom: 20px;
}
.inputs__elements__style-check p {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: #1759A5;
}
.checkbox__elements__style {
    display: flex;
    align-items: center;
    gap: 8px;
}
.checkbox__elements__style input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    border: 1px solid #1E2E3F;
    border-radius: 50%;  
    margin-bottom: 3px;
}
.checkbox__elements__style label {
    margin-right: 20px;
    color: #1E2E3F;
    font-size: 14px;
    font-weight: 600;
}
.address__title {
    margin: 15px 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: #1759A5;
    margin-bottom: 10px;
}
.second__section {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.width__100 {
    width: 100%;
}
.inputs__elements__style-second-section input,
.inputs__elements__style-second-section select {
    min-width: 235px;
    max-width: 235px;
}
.flex__element {
    display: flex;
    align-items: center;
    gap: 24px;
}
.flex__element div:nth-child(2) {
    margin-top: 28px;
}
.container-btn-file input[type="file"] {
    appearance: none;
    display: none;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
}
.container-btn-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #295AA0;
    color: #fff;
    border: 0;
    padding: 12px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);
    overflow: hidden;
    transition: all .3s ease;
    width: 280px;
    height: 40px;
    font-weight: 600;
}
.container-btn-file-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #295AA0;
    color: #fff;
    border: 0;
    padding: 12px 15px;
    border-radius: 20px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);
    overflow: hidden;
    transition: all .3s ease;
    /* width: 280px; */
    height: 40px;
    font-weight: 600;
}
.container-btn-file:hover {
    background-color: #72D8F7;
    color: #1E2E3F;
}
.container-btn-file:active {
    background-color: #114378;
}
.container-btn-file > i {
    margin-right: 15px;
}
.container-btn-file label {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer;
}
.files {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.files .files__text-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    font-size: 12px;
    padding: 8px 20px;
    border-radius: 30px;
    color: #1759A5;
}
.files .files__text-label i {
    margin-right: 10px;
}