/* Searcher */
.search_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 21px;
  overflow-y: auto;
  position: absolute;
  z-index: 19;
  height: 70px;
  overflow: hidden;
}
.search_container:hover input {
  width: 400px;
}
.search_btn i {
  font-size: 20px;
  color: white;
  padding-bottom: 20px;
  transition: all 0.7s ease;
}

.search_btn:hover {
  background: #295aa0;
}
.search_input {
  outline: none;
  box-sizing: border-box;
  height: 35px;
  width: 0px;
  padding: 0 20px;
  color: #000;
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid #295aa0;
  transition: all 0.7s ease;
}
.search_input::placeholder {
  color: grey;
}
.search_btn {
  position: absolute;
  right: 0px;
  top: 14px;
  width: 42px;
  height: 42px;
  margin-top: 0px;
  background: #295aa0;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}

/* Profile */

section {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 27%;
  padding: 10px 20px;
  border-radius: 5px;
}
section h3 {
  text-align: center;
  font-size: 20px;
  color: #1759a5;
}
.btn1 {
  padding: 10px;
  border-radius: 20px;
  outline: none;
  border: 0.1px solid #f0f0f0;
  background: #295aa0;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  font-size: 14px;
  font-weight: bold;
  width: 120px;
}
.btn1:hover {
  transform: scale(1.05);
}
.display_container1 {
  display: flex;
}
.profile_container1 {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
.img_container1 {
  position: relative;
  margin-right: 20px;
}
.img_container1 img {
  width: 100px;
  height: 101px;
  border-radius: 100%;
  border: 1px solid #dddddd;
  transition: 0.5s ease-in-out all;
  object-fit: cover;
}
.img_container1:hover img {
  opacity: 0.4;
}
.img_container1:hover .overlay1 {
  opacity: 1;
}
.overlay1 {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.text_container1 {
  flex-grow: 1;
  margin-bottom: 20px;
}
.text_container1 hr {
  background: #dddddd;
  border: none;
  height: 1px;
}
.exit_chat_button {
  position: absolute;
  top: 5;
  left: 20;
  button {
    background-color: #1759a5;
    color: white;
    font-weight: bold;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s all;
    &:hover {
      background-color: #0084ff;
    }
  }
}
.app-simple1 {
  position: relative;
}
.text_container1 h3 {
  text-align: left;
}
.home_container1 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  height: calc(100vh - 70px);
  width: 100%;
}
.home_compressed {
  overflow: hidden;
  height: calc(100vh - 10px);
  display: grid;
  position: relative;
  margin-left: 50px;
}
.compressedChat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      border: 1px solid #295aa0;
      padding: 20px;
      border-radius: 5px;
      width: 90%;
      font-size: 20;
      align-self: center;
    }
  }
  &_buttons {
    margin-top: 14px;
    button {
      border-radius: 30px;
      font-size: 13px;
      font-weight: bold;
      padding: 8px 20px;
    }
    .send {
      background-color: #1759a5;
      color: white;
      margin-left: 20px;
      &:hover {
        color: #1759a5;
      }
    }
    .upload {
      background-color: #72d8f7;
      color: #1759a5;
      margin-left: 15px;
      label {
        margin-left: 10px;
        height: 20px;
        margin-top: -8px;
      }
    }
  }
}
.users_container1 {
  margin-top: 0px;
  border-right: 1px solid #dddddd;
  overflow-y: auto;
  margin-bottom: 17px;
}
.user_wrapper1 {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 80px;
  margin-bottom: -70px;
}
.user_wrapper1:hover {
  background: #f0f0f0;
}

.user_info1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_detail1 {
  display: flex;
  align-items: center;
}
.user_detail1 h4 {
  margin-left: 10px;
}
.avatar1 {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-left: 10px;
}
.user_status1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.online1 {
  background: #34eb52;
}
.offline1 {
  background: #eb4034;
}
.messages_container1 {
  position: relative;
  width: 100%;
}
.messages_user1 {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #dddddd;
}
.user_selected1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_selected1 img {
  margin-right: 10px;
}
.no_conv1 {
  font-size: 20px;
  color: grey;
  text-align: center;
}
.message_form1 {
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.message_form1 input {
  width: 40vw;
  margin: 0px 10px 10px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
.message_form1 .btn1 {
  margin-top: -10px;
}
.messages1 {
  height: calc(100vh - 240px);
  overflow-y: auto;
  border-bottom: 1px solid #dddddd;
}
.message_wrapper1 {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 5px;
  padding: 0px 15px;
}

.avatarChat {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
  margin-left: 0 !important;
}
.message_wrapper1 img {
  width: 100%;
  border-radius: 5px;
}
.message_wrapper1 p {
  padding: 20px;
  display: inline-block;
  width: 85%;
  text-align: left;
  border-radius: 8px;
}
.message_wrapper1 small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}
.me1 {
  background: white;
  color: black;
  box-shadow: 0px 0px 28px 2px rgba(0, 0, 0, 0.29);
}
.friend1 {
  background: white;
  box-shadow: 0px 0px 28px 2px rgba(0, 0, 0, 0.29);
}
.selected_user1 {
  background: #f0f0f0;
}
.truncate1 {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.truncate1 strong {
  margin-right: 10px;
}
.unread1 {
  margin-left: 10px;
  background: #0084ff;
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}
.sm_screen1 {
  display: none;
}

.document_description {
  width: 100%;
  background-color: white;
  height: 120px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px 9px 9px -11px rgba(0, 0, 0, 0.75);
  .document_image {
    margin-left: 15px;
    padding: 18px;
    border-radius: 50%;
    background-color: #72d8f7;
  }
  .document_description_text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-left: 18px;
    color: #1759a5;
    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .document_title {
      font-weight: bolder;
      font-size: 18px;
    }
    .document_type {
      font-size: 14px;
      margin-bottom: 3px;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 767px) {
  .home_container1 {
    grid-template-columns: 2fr 3fr;
  }
  .message_form1 {
    left: 3%;
    right: 0;
    bottom: 5px;
  }
  .message_wrapper1 p {
    max-width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .home_container1 {
    grid-template-columns: 1fr 5fr;
  }
  .home_compressed {
    margin-left: 0;
  }
  .user_wrapper1 {
    display: none;
  }
  .messages1 {
    padding: 15px;
  }
  .sm_container1 {
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
    margin-top: 70px;
    margin-bottom: -70px;
  }
  .sm_screen1 {
    display: inline-block;
  }
  .message_form1 input {
    width: 50vw;
    margin: 0px 10px;
  }
  .message_form1 .btn1 {
    margin: 0px;
  }
  .message_wrapper1 p {
    max-width: 100%;
  }
  .exit_chat_button {
    display: none;
  }
  .document_description {
    justify-content: center;
  }
  .document_image {
    background-color: white !important;
    border: 2px solid #1759a5;
  }
}
