.admin__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 40px auto 60px auto;
    min-width: 90%;
    background-color: #E2E2E2;
    padding: 40px 0px;
}
.admin__container h2 {
    color: #434343;
}
.admin__container form hr {
    border: none;
    height: 1px;
    background-color: #646464;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
}
.form__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.text__container {
    margin: 0 20px;
}
.text__container input {
    border-radius: 5px;
    border: 2px solid #295aa0;
    background: transparent;
}
.text__container input:focus {
    outline: none;
}
.colors__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px 20px;
}
.text__container input {
    padding: 5px 10px;
    width: 90%;
}
.admin__container-element {
    margin: 10px 10px;
}
.admin__container-element p {
    margin-bottom: 0;
    margin-top: 5px;
}
.admin__container-element input {
    margin-top: 10px;
}
.admin__container-element-upload p {
    margin: 0px;
}
.admin__container-element input {
    width: 100px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    background-color: transparent;
}
.image__upload__container {
    margin: 0 20px;
    padding-top: 15px;
}
.admin__container-element-upload input {
    margin-top: 10px;
}
.image__upload__container div:first-child {
    margin-bottom: 30px;
}
.modules__section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modules__section-elements {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;  
}
.modules__section-checkbox {
    height: 40px;
    display: flex;
    align-items: center;
}
.modules__section-checkbox label {
    margin-bottom: 6px;
}
.modules__section-checkbox input {
    margin: 0px 10px;
    cursor: pointer;
    outline: none;
}
.modules__section-checkbox input[type="checkbox"] {
    transform: scale(1.5);
    padding: 10px;
}
.button__container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 0px;
}
.button__container button {
    cursor: pointer;
    background-color: #1759A5;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 600;
    transition: 0.5s;
}
.button__container button:hover {
    background-color: #72D8F7;
}