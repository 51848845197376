/* Profile */
.display_container1 {
  display: flex;
}

.profile_container1 {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.img_container1 {
  position: relative;
  margin-right: 20px;
}

.img_container1 img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid grey;
  transition: 0.5s ease-in-out all;
  object-fit: cover;
}

.img_container1:hover img {
  opacity: 0.4;
}

.img_container1:hover .overlay {
  opacity: 1;
}

.hidden-normal {
  display: flex;
}

.hidden-responsive {
  display: none;
}

.hidden-options {
  display: none;
}

.overlay1 {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text_container1 {
  flex-grow: 1;
  margin-bottom: 20px;
}

.text_container1 h3 {
  text-align: left;
}

.home_container1 {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  height: calc(100vh - 70px);
  width: 100vw;
}

.users_container1 {
  margin-top: 10px;
  border-right: 2px solid #333333;
  overflow-y: auto;
}

.user_wrapper1 {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.user_info1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_detail1 {
  display: flex;
  align-items: center;
}

.user_detail1 h4 {
  margin-left: 10px;
}

.avatar1 {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.user_status1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.online1 {
  background: #34eb52;
}

.offline1 {
  background: #eb4034;
}

.messages_container1 {
  position: relative;
  width: 100%;
}

.messages_user1 {
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #333333;
}

.user_selected1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_selected1 img {
  margin-right: 10px;
}

.no_conv1 {
  font-size: 20px;
  color: grey;
  text-align: center;
}

.messageInput {
  width: 100%;
}

.message_form1 {
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;

  &.short {
    position: relative !important;
    margin-top: 10px;
    left: 0 !important;

    .text-option {
      height: 30px;
      width: 97%;
      border: 1px solid #1759a5;
      margin: 0 !important;
      margin-top: -13px !important;
    }
    .input-options {
      display: flex;
      position: absolute;
      top: 60px;

      .text-option {
        position: relative;
      }

      .btn1 {
        margin-top: 0 !important;
        &:hover {
          background-color: #72d8f7;
          color: #1759a5;
          transform: none;
        }
      }

      label {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        gap: 6px;
        align-items: center;
        background-color: #72d8f7;
        padding: 8px 20px;
        border-radius: 20px;
        font-weight: bold;
        color: #1759a5;
        transition: 0.3s all;
        &:hover {
          background-color: #1759a5;
          color: white;
        }
      }
    }
  }
}

.message_form1 .btn1 {
  margin-top: -10px;
}

.messages1 {
  height: calc(100vh - 200px);
  overflow-y: auto;
  border-bottom: 1px solid #333333;
}

.message_wrapper1 {
  margin-top: 5px;
  padding: 0px 5px;
}

.message_wrapper1 img {
  width: 100%;
  border-radius: 5px;
}

.message_wrapper1 p {
  padding: 10px;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
}

.message_wrapper1 small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}
.userNameMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1759a5;
  text-align: center;
  font-weight: bolder;
}

.me1 {
  background: #0084ff;
  color: white;
}

.friend1 {
  background: #333333;
}

.selected_user1 {
  background: #333333;
}

.truncate1 {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate1 strong {
  margin-right: 10px;
}

.unread1 {
  margin-left: 10px;
  background: #0084ff;
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}

.sm_screen1 {
  display: none;
}

@media screen and (max-width: 767px) {
  .home_container1 {
    grid-template-columns: 2fr 3fr;
  }

  .message_form1 {
    left: 3%;
    right: 0;
    bottom: 5px;
  }

  .message_wrapper1 p {
    max-width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .home_container1 {
    grid-template-columns: 1fr 5fr;
  }

  .user_wrapper1 {
    display: none;
  }

  .sm_container1 {
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
  }

  .sm_screen1 {
    display: inline-block;
  }

  .message_form1 .btn1 {
    margin: 0px;
  }

  .message_wrapper1 p {
    max-width: 100%;
  }

  .messageInput {
    display: flex;
    justify-content: left;
    margin: 0 auto;
    margin-top: 5px !important;
    width: 80%;
    input {
      border: none !important;
    }
    .text-option {
      width: 68% !important;
    }
    .hidden-options {
      display: flex;
      position: absolute;
      flex-direction: row;
      align-items: center;
      right: 10px;
      top: 0;
      button {
        border: none;
        background-color: transparent;
        padding: 0;
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        border: 1px solid #1759a5;
      }
    }
  }

  .message-menu-options {
    display: none;
  }

  .hidden-normal {
    display: none;
  }

  .hidden-responsive {
    margin-top: 65px;
    padding-bottom: 20px;
    display: flex;
    position: relative;
    flex-direction: row;
  }
  .responsive-options {
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      img {
        height: 45px;
        width: 45px;
        padding: 0;
        margin-top: 4px;
      }
    }
    label {
      border: 1px solid #1759a5;
      border-radius: 50%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
  .home_container1{
    padding-bottom: 30px;
  }
}
